<template>
    <!-- <PartnerHeader /> -->
    <UHeader title="NomadRx" to="/dashboard">
        <template #logo>
            <p class="tracking-tight">{{ partnerName || 'NomadRx' }}</p>
        </template>
        <template #right>
            <div class="hidden lg:flex items-center gap-1.5">
                <UDropdown :items="accountItems">
                    <UButton color="gray" variant="ghost" square icon="i-heroicons-user-solid" />
                </UDropdown>
            </div>
        </template>
        <template #panel>
            <UHeaderPopoverLinks :links="links" />
            <UDivider class="my-4" />
            <div class="mt-4 space-y-2">
                <UButton label="Sign out" icon="i-heroicons-arrow-left-end-on-rectangle" @click="signOutUser" block
                    variant="soft" size="xl" />
            </div>
        </template>
    </UHeader>
    <UMain>
        <UContainer>
            <UPage>
                <template #left>
                    <UAside>
                        <UAsideLinks :links="links" class="pt-0" :ui="{ wrapper: 'space-y-4' }">
                            <template #label>
                                <p class="text-sm font-semibold text-gray-500">Dashboard</p>
                            </template>
                        </UAsideLinks>
                    </UAside>
                </template>
                <div class="mb-20">
                    <slot />
                </div>
            </UPage>
        </UContainer>
    </UMain>
</template>


<script setup lang="ts">
const { signOutUser } = useFirebaseAuth();

const partnerStore = usePartnerStore();
const { partner } = storeToRefs(partnerStore);

const accountItems = [
    [{
        label: 'Go to Scribe',
        icon: 'i-mdi-quill',
        to: "/scribe"
    }], [{
        label: 'Settings',
        icon: 'i-heroicons-cog-8-tooth',
        to: "/dashboard/settings"
    }], [{
        label: 'Sign out',
        icon: 'i-heroicons-arrow-left-end-on-rectangle',
        click: async () => {
            await signOutUser();
        }
    }]
]
const partnerName = computed(() => {
    return partner.value ? partner.value.short_name : 'NomadRx'
})

const links = [
    {
        label: 'Clients',
        to: '/dashboard/clients',
        // icon: 'i-heroicons-user-group',
        exact: false,
    },
    {
        label: 'Authorizations',
        to: '/dashboard/authorizations',
        // icon: 'i-heroicons-home',
        exact: false,
    }, {
        label: 'Claims',
        to: '/dashboard',
        // icon: 'i-heroicons-home',
        exact: true,
    },
    {
        label: 'Payouts',
        to: '/dashboard/payouts',
        // icon: 'i-heroicons-banknotes',
    }
]

</script>
